import React from "react";

import Address from "./address";
import Hours from "./hours";
import Phone from "./phone";
import YelpBadge from "./badge-yelp";

export default function Footer() {
  return (
    <footer className="sv-footer center">
      <nav className="mx-auto md-mb0 md-pt5">
        <ul className="list-reset flex flex-wrap my0 md-pl4 md-pr4 md-mxn4">
          <li className="pt3 md-pt0 md-px4 col-12 md-col-3 lg-col-2">
            <h2 className="sv-footer-header h7 pb2">Contact Us</h2>
            <div className="pb3 md-mx0">
              <Phone />
            </div>
            <div className="pb3 md-mx0">
              <Address />
            </div>
            <hr className="md-hide lg-hide col-12 mx0" />
          </li>

          <li className="pt3 md-pt0 md-px4 col-12 md-col-3 lg-col-3">
            <h2 className="sv-footer-header h7 pb2">Office Hours</h2>
            <div className="pb5">
              <Hours />
            </div>
            <hr className="md-hide lg-hide col-12 mx0" />
          </li>

          <li className="flex-auto pt3 pb3 md-pt0 md-px4 col-12 md-col-3 lg-col-3">
            <YelpBadge />
          </li>
        </ul>
      </nav>
    </footer>
  );
}
