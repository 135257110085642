import React from "react";

import yelpFavicon from "../assets/yelp_favicon.svg";

export default function YelpBadge() {
  return (
    <div className="yelp-badge">
      <a
        href="http://yelp.com/biz/simply-vision-optometry-san-leandro?utm_medium=badge_reviews&amp;utm_source=biz_review_badge"
        className="text-decoration-none"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={yelpFavicon}
          srcSet={yelpFavicon}
          width="45"
          alt="Check out Simply Vision Optometry on Yelp"
        />
        <div className="yelp-text p1">Check us out on Yelp</div>
      </a>
    </div>
  );
}
