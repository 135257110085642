import React from "react";

export default function Phone() {
  return (
    <a
      className="text-decoration-none"
      href="tel:5108952020"
      style={{ whiteSpace: "nowrap" }}
    >
      (510) 895-2020
    </a>
  );
}
