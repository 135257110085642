import React from "react";

import calendarIcon from "../assets/icons8-calendar.svg";
import closeIcon from "../assets/icons8-close.svg";
import AppContext from "../context/app-context";
import useEscapeHandler from "../hooks/escape-handler";
import useKeyHandler from "../hooks/use-key-handler";
import Spinner from "./spinner";

export function Lightbox() {
  const context = React.useContext(AppContext);
  const cls = "lightbox" + (context.lightboxIsOpen ? " open" : "");
  const handleKeyDown = useKeyHandler(context.toggleLightbox, ["Enter", " "]);
  useEscapeHandler(context.lightboxIsOpen, context.toggleLightbox);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!context.lightboxIsOpen) {
      setLoading(true);
      return;
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [context.lightboxIsOpen]);

  function handleButtonKeyDown(e) {
    if (handleKeyDown(e.key)) {
      e.preventDefault();
    }
  }

  return (
    <div className={cls}>
      <div className="content">
        <div className="header">
          <h4 className="title">Schedule Appointment</h4>
          <a
            className="link-out pr1 italic regular"
            href="https://app.eyecloudpro.com/site/!appt_req?sid=5C241BA24405C1755C8C3373345C7FF0704A0191FDE0B69AE91EC7E31B6D02D9"
            target="_blank"
          >
            Not loading? Click here
          </a>
          <span
            className="close"
            onClick={context.toggleLightbox}
            onKeyDown={handleButtonKeyDown}
            role="button"
            tabIndex={0}
          >
            <img src={closeIcon} width="26" height="26" alt="Close"></img>
          </span>
        </div>
        <div className="body">
          {loading && <Spinner />}
          {context.lightboxIsOpen && (
            <iframe
              src="https://app.eyecloudpro.com/site/!appt_req?sid=5C241BA24405C1755C8C3373345C7FF0704A0191FDE0B69AE91EC7E31B6D02D9"
              title="Schedule Appointment"
              frameBorder="0"
              style={{
                visibility: loading ? "hidden" : "visible",
              }}
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
}

export function LightboxButton(props) {
  const context = React.useContext(AppContext);
  const handleKeyDown = useKeyHandler(context.toggleLightbox, ["Enter", " "]);
  const { allowResponsiveIcon } = props;

  function handleButtonKeyDown(e) {
    if (handleKeyDown(e.key)) {
      e.preventDefault();
    }
  }

  return (
    <span className="sv-headerbar-fixed-link text-decoration-none mr2">
      {allowResponsiveIcon ? (
        <>
          <div
            className="mr2 md-hide lg-hide"
            onClick={context.toggleLightbox}
            onKeyDown={handleButtonKeyDown}
            role="button"
            tabIndex={0}
          >
            <img
              src={calendarIcon}
              width="32"
              height="32"
              alt="Schedule Appointment"
            />
          </div>
          <div className="xs-hide sm-hide pr3">
            <div
              className="sv-btn sv-btn-secondary caps md-ml4"
              onClick={context.toggleLightbox}
              onKeyDown={handleButtonKeyDown}
              role="button"
              tabIndex={0}
            >
              schedule appointment
            </div>
          </div>
        </>
      ) : (
        <div
          className="sv-btn sv-btn-secondary caps md-ml4"
          onClick={context.toggleLightbox}
          onKeyDown={handleButtonKeyDown}
          role="button"
          tabIndex={0}
        >
          schedule appointment
        </div>
      )}
    </span>
  );
}
