import React from "react";

export default function Hours() {
  return (
    <ul className="office-hours list-reset mt0 md-mx0 inline-block nowrap">
      <li>
        <div>Mon:</div>
        <div>Closed</div>
      </li>
      <li>
        <div>Tue:</div>
        <div>9:30 AM - 4:00 PM</div>
      </li>
      <li>
        <div>Wed:</div>
        <div>Closed</div>
      </li>
      <li>
        <div>Thu:</div>
        <div>9:30 AM - 4:00 PM</div>
      </li>
      <li>
        <div>Fri:</div>
        <div>Closed</div>
      </li>
      <li>
        <div>Sat:</div>
        <div>9:30 AM - 4:00 PM</div>
      </li>
      <li>
        <div>Sun:</div>
        <div>Closed</div>
      </li>
    </ul>
  );
}
