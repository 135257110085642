import React from "react";

export default function useEscapeHandler(isActive, callback) {
  const upHandler = React.useCallback(
    function (evt) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape) {
        callback();
      }
    },
    [callback]
  );

  React.useEffect(() => {
    if (!isActive) {
      return;
    }
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keyup", upHandler);
    };
  }, [isActive, upHandler]);
}
