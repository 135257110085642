import React from "react";
import { Helmet } from "react-helmet";

import { Lightbox } from "./appt-lightbox";
import Footer from "./footer";
import Header from "./header";
import Sidebar from "./sidebar";
import logoEye from "../assets/logo-eye.png";
import AppContext from "../context/app-context";

export default function Layout({ children, pageTitle }) {
  const context = React.useContext(AppContext);
  const showOverlay = context.sidebarIsOpen || context.lightboxIsOpen;
  const metaTitle =
    "Simply Vision Optometry (San Leandro, CA)" +
    (pageTitle ? ` - ${pageTitle}` : "");

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta
          name="description"
          content="Simply Vision Optometry is located in downtown San Leandro, CA, serving the East Bay  and surrounding San Francisco Bay Area.  We provide comprehensive eye exams, contact  lens fittings, emergency eye care, and LASIK co-management.  Come see Dr. Kalli Leung  for your eye care needs."
        />
        <meta
          name="keywords"
          content="san leandro optometry, san leandro eye doctor, oakland optometry, oakland eye doctor, castro valley optometry, castro valley eye doctor, hayward optometry, hayward eye doctor, optometrist, optometry, Dr. Kalli Leung, eye care, eye doctor, vision care, contact lenses, sunglasses, reading glasses"
        />
        <meta
          name="google-site-verification"
          content="6OmC4fH3XhrZkfQA_OO-5HwkL9tg0ImgbvCrZQTMRvs"
        />
        <link rel="icon" type="image/png" href={logoEye} />
        <link rel="canonical" href="https://www.simply2020.com" />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700"
          rel="stylesheet"
        ></link>
      </Helmet>

      <Header toggleSidebar={context.toggleSidebar} />
      {children}
      <Footer />
      <Lightbox />
      <Sidebar isOpen={context.sidebarIsOpen} toggle={context.toggleSidebar} />
      <div
        id="overlay-mask"
        className={showOverlay ? "" : "display-none"}
      ></div>
    </>
  );
}
