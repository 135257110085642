import React from "react";
import { Link } from "gatsby";

import { LightboxButton } from "./appt-lightbox";
import logo from "../assets/logo-header.png";
import useKeyHandler from "../hooks/use-key-handler";

export default function Header(props) {
  const { toggleSidebar } = props;
  const maybeToggleSidebar = useKeyHandler(toggleSidebar, ["Enter", " "]);

  function toggleSidebarKeyDown(e) {
    if (maybeToggleSidebar(e.key)) {
      e.preventDefault();
    }
  }

  return (
    <header className="sv-headerbar fixed flex justify-start items-center top-0 left-0 right-0 pl2 pr4 pt2 md-pt0">
      <div
        role="button"
        aria-label="open sidebar"
        tabIndex="0"
        className="sv-navbar-trigger pr2 absolute top-0 pr0 mr2 mt2"
        onClick={toggleSidebar}
        onKeyDown={toggleSidebarKeyDown}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          className="block"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            fill="currentColor"
            d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"
          ></path>
        </svg>
      </div>
      <Link
        to="/"
        className="text-decoration-none inline-block mx-auto sv-headerbar-home-link mb1 md-mb0"
      >
        <img
          src={logo}
          width="198"
          height="40"
          className="my0 mx-auto"
          alt="Simply Vision Optometry"
        />
      </Link>
      <div className="sv-headerbar-fixed center m0 p0 flex justify-center nowrap absolute top-0 right-0 pt2">
        <LightboxButton allowResponsiveIcon={true} />
      </div>
    </header>
  );
}
