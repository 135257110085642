import React from "react";

export default function Address() {
  return (
    <a
      className="text-decoration-none"
      href="http://goo.gl/maps/jCT5e"
      target="_blank"
      rel="noreferrer"
    >
      <address style={{ whiteSpace: "nowrap" }}>
        <div>1371 E. 14th St.</div>
        <div>Suite A</div>
        <div>San Leandro, CA 94577</div>
      </address>
    </a>
  );
}
